import { Link } from "gatsby"
import React from "react"

export default function About() {
  return (
    <div class="about">
    <p>Currently, we are working on two projects.</p>
    <p>The first one is a film on the institution of racism at Duke.</p>
    <p>The second one,     <Link to="/mutualplayers" >Mutual Players</Link>, is a collage film commenting on the image of Asians in Hollywood.</p>
    </div>
  )
}